<app-header></app-header>
<app-sidebar></app-sidebar>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple">
  <p style="font-size: 15px; color: white;text-align: center;">Chargement ... </p>
</ngx-spinner>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">
                  <app-fildariane></app-fildariane>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
      </div>
      <!-- end row -->
      <div class="row">
        <a href="javascript:window.history.back();" class="btn btn-primary waves-effect waves-light btn-sm mb-3"><i class="mdi mdi-chevron-left ml-1"></i> Retour </a>

        <div class="card col-lg-12">
          <div class="card-body">
            <div class="container-fluid mobile_container">
              <div class="row text-left">
                <div class="col-md-2 col-lg-3"> <span class="card-title label_info">Matricule </span> : {{user_info_certifications.matricule}}</div>
                <div class="col-md-2 col-lg-3"> <span class="card-title label_info">NOM </span> : {{user_info_certifications.name}}</div>
                <div class="col-md-2 col-lg-3">  <span class="card-title label_info">PRENOM </span> : {{user_info_certifications.firstname}}</div>
                <div class="col-md-2 col-lg-3"> <span class="card-title label_info">Service </span> : {{user_info_certifications.service ? user_info_certifications.service : 'Non défini'}}</div>
                <div class="col-md-2 col-lg-3"> <span class="card-title label_info">Date de début </span> : {{user_info_certifications.start_date === '01/01/1970' ? 'Non défini' : user_info_certifications.start_date}}</div>
                <div class="col-md-2 col-lg-3"> <span class="card-title label_info">Date de fin </span> : {{user_info_certifications.end_date === '01/01/1970' ? 'Non défini' : user_info_certifications.end_date}}</div>
                <div class="col-md-2 col-lg-3"> <span class="card-title label_info">Status </span> :
                  <span class="badge badge-success" *ngIf="user_info_certifications.active===1">ACTIF</span>
                  <span class="badge badge-danger" *ngIf="user_info_certifications.active===0">NON ACTIF</span>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid mobile_container">
            <div class="row head_row text-left">
              <div class="col-md-2 col-lg-3 tt_nom_certif only_pc">
                <i class="bx bxs-check-shield"></i> Certification<br>
                <i class="bx bx-subdirectory-right"></i>Titre du parcours
              </div>
              <div class="col-md-2 col-lg-1 tt_valid_certif only_pc">
                <i class="bx bx-info-circle"></i> Statut du certificat
              </div>
              <div class="col-md-2 col-lg-2 tt_date_certif only_pc">
                <i class="bx bx-info-circle"></i> Statut du parcours
              </div>
              <div class="col-md-2 col-lg-2 tt_valid_certif only_pc">
                <i class="bx bx-time"></i> Durée de validité en mois
              </div>
              <div class="col-md-2 col-lg-2 tt_date_certif only_pc">
                <i class="bx bx-calendar"></i> Date de certification
              </div>
              <div class="col-md-2 col-lg-2 tt_date_certif only_pc">
                <i class="bx bx-calendar"></i> Date de fin de validité
              </div>
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="certificats && certificats?.length === 0">
              <p style="text-align: center; margin:20px 0 20px 0;">Cet apprenant ne possède aucun certificat !</p>
            </div>
            <div class="row list_scroll">
              <div class="row text-left certif_wrap" *ngFor="let certification of certificats; let i = index;">
                <ng-container *ngIf="certification.code_cert.substring(0,3)==='CK1'">

                  <div class="col-md-2 col-lg-3">
                    <div class="label_cert">
                      <span class="only_mobile">Certification : </span>{{certification.name}}<br>
                      <span class="alert-danger">{{certification.is_register === false ? 'Non inscrit ou non commencé':''}}</span>
                    </div>
                  </div>
                  <div class="col-md-2 col-lg-1">
                    <ng-container *ngFor="let certificationResult of certificationResults; let v = index;">

                      <ng-container *ngIf="certificationResult!=null && certificationResult.code_cert === certification.code_cert">
                        <ng-container *ngIf="certificationResult.passed >= 1 && certificationResult.not_validated == 0">
                          <span class="col_valid valid">Valide</span>
                        </ng-container>
                        <ng-container *ngIf="certificationResult.not_validated > 0">
                                <span class="col_valid no_valid">Invalidé
                                </span>
                        </ng-container>
                        <ng-container *ngIf="certificationResult.passed == 0 && certificationResult.not_validated == 0">
                                <span class="col_valid no_valid">Non valide
                                </span>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                  <div class="col-md-2 col-lg-2"> </div>
                  <div class="col-md-2 col-lg-2"> </div>
                  <div class="col-md-2 col-lg-2"> </div>
                  <div class="col-md-2 col-lg-2"> </div>

                  <ng-container *ngFor="let sucessArray of sucessArrays; let k = index">
                    <ng-container *ngIf="sucessArray.code_cert === certification.code_cert">

                      <div class="col-md-2 col-lg-3">
                        <span class="only_mobile titre_parcours">Titre du parcours : </span>{{sucessArray.title}}
                      </div>
                      <div class="col-md-2 col-lg-1"></div>
                      <div class="col-md-2 col-lg-2">
                        <ng-container *ngIf="sucessArray.failed==='yes'">
                                    <span class="col_valid">
                                      <ng-container *ngIf="sucessArray.expired === 'yes'"> expiré</ng-container>
                                      <ng-container *ngIf="sucessArray.incomplete === 'yes'">en cours</ng-container>
                                    </span>
                        </ng-container>
                        <ng-container *ngIf="sucessArray.success==='yes'">
                          <span class="only_mobile">Statut du parcours : </span><span class="col_valid"> valide</span>
                        </ng-container>
                        <ng-container *ngIf="sucessArray.notvalidated==='yes'">
                          <span class="only_mobile">Statut du parcours : </span><span class="col_valid"> invalidé</span>
                        </ng-container>
                      </div>
                      <div class="col-md-2 col-lg-2">
                        <span class="only_mobile">Durée de validité : </span>{{certification.type_validite === 2 ? certification.delay + " mois" : certification.date }}
                      </div>
                      <div class="col-md-2 col-lg-2">
                                <span class="col_date">
                                <ng-container *ngIf="sucessArray.success==='yes' || sucessArray.expired === 'yes' || sucessArray.notvalidated === 'yes'"><span class="only_mobile">Date de certification : </span>{{sucessArray.date_dernier_passage | date: 'dd/MM/yyyy'}}</ng-container>

                                    </span>
                      </div>
                      <div class="col-md-2 col-lg-2">
                                <span class="col_date">
                                <ng-container *ngIf="certification.type_validite === 2">
                                  <ng-container *ngIf="sucessArray.success==='yes' || sucessArray.expired === 'yes'">
                                    <ng-container *ngIf="sucessArray.date_dernier_passage==='1970-01-01 00:00:00'"><span class="col_valid not_attempted"><i class="bx bx-info-circle"></i> NC</span></ng-container>
                                    <ng-container *ngIf="sucessArray.date_dernier_passage!=='1970-01-01 00:00:00'"><span class="only_mobile">Date de fin de validité : </span>{{sucessArray.dateEndValidite | date: 'dd/MM/yyyy'}}</ng-container>
                                  </ng-container>
                                  <ng-container *ngIf="sucessArray.notvalidated === 'yes'">
                                    <ng-container  *ngIf="user_info_certifications.last_exit_date != null">{{user_info_certifications.last_exit_date | date: 'dd/MM/yyyy'}}</ng-container>
                                    <ng-container  *ngIf="user_info_certifications.last_exit_date === null"><span class="col_valid not_attempted">NC</span></ng-container>
                                  </ng-container>
                                </ng-container>
                                  <ng-container *ngIf="certification.type_validite === 1">
                                    <ng-container *ngIf="sucessArray.success==='yes' || sucessArray.expired === 'yes'">
                                      <ng-container *ngIf="certification.date==='1970-01-01 00:00:00'"><span class="col_valid not_attempted"><i class="bx bx-info-circle"></i> NC</span></ng-container>
                                      <ng-container *ngIf="certification.date!=='1970-01-01 00:00:00'"><span class="only_mobile">Date de fin de validité : </span>{{certification.date | date: 'dd/MM/yyyy'}}</ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="sucessArray.notvalidated === 'yes'">
                                      <ng-container  *ngIf="user_info_certifications.last_exit_date != null">{{user_info_certifications.last_exit_date | date: 'dd/MM/yyyy'}}</ng-container>
                                      <ng-container  *ngIf="user_info_certifications.last_exit_date === null"><span class="col_valid not_attempted">NC</span></ng-container>
                                    </ng-container>
                                  </ng-container>
                                  </span>
                      </div>

                    </ng-container>

                    <ng-container *ngIf="sucessArray.parent_certificat_id !=='' && sucessArray.parent_certificat_id ===certification.id">
                      <ng-container *ngIf="sucessArray.failed==='yes'">
                                          <span class="col_valid no_valid">Non valide
                                            <ng-container *ngIf="sucessArray.expired === 'yes'"> (expiré)</ng-container>
                                            <ng-container *ngIf="sucessArray.incomplete === 'yes'"> (non terminé)</ng-container>
                                          </span>
                      </ng-container>
                      <ng-container *ngIf="sucessArray.notattempted==='yes'">
                        <span class="col_valid not_attempted"><i class="bx bx-info-circle"></i> NC</span>
                      </ng-container>
                      <ng-container *ngIf="sucessArray.code_cert.substring(0,3)==='CK2'">
                        <ng-container *ngIf="sucessArray.code_cert===FirstCK2">
                          <div class="label_ss_cert mt-2 mb-1">Sous-certifications :</div>
                        </ng-container>
                        <div class="col-md-2 col-lg-3">
                          <div class="label_cert ml-2">
                            <span class="only_mobile">Sous Certification : </span>{{sucessArray.sub_cert_name}}<br>
                            <span class="alert-danger">{{sucessArray.is_register === false ? 'Non inscrit ou non commencé':''}}</span>
                          </div>
                        </div>
                        <div class="col-md-2 col-lg-1">
                          <ng-container *ngIf="sucessArray.success === 'yes'">
                            <span class="col_valid valid">Valide</span>
                          </ng-container>
                          <ng-container *ngIf="sucessArray.score !=100">
                            <span class="col_valid no_valid">Non valide</span>
                          </ng-container>
                        </div>
                        <div class="col-md-2 col-lg-2"> </div>
                        <div class="col-md-2 col-lg-2"> </div>
                        <div class="col-md-2 col-lg-2"> </div>
                        <div class="col-md-2 col-lg-2"> </div>

                        <div class="col-md-2 col-lg-3">
                          <div class="ml-2">
                            <span class="only_mobile titre_parcours">Titre du parcours : </span>{{sucessArray.title}}
                          </div>
                        </div>
                        <div class="col-md-2 col-lg-1"></div>
                        <div class="col-md-2 col-lg-2">
                                    <span class="col_valid">
                                      <ng-container *ngIf="sucessArray.expired === 'yes'">expiré</ng-container>
                                      <ng-container *ngIf="sucessArray.incomplete === 'yes'">en cours</ng-container>
                                      <ng-container *ngIf="sucessArray.success === 'yes'">valide</ng-container>
                                      <ng-container *ngIf="sucessArray.failed === 'yes'">failed</ng-container>
                                      <ng-container *ngIf="sucessArray.notvalidated === 'yes'">invalidé</ng-container>
                                    </span>
                        </div>
                        <div class="col-md-2 col-lg-2">
                          <span class="only_mobile">Durée de validité : </span>{{sucessArray.type_validite === 2 ? sucessArray.delay + " mois" : sucessArray.date }}
                        </div>
                        <div class="col-md-2 col-lg-2">
                                <span class="col_date">
                                <ng-container *ngIf="sucessArray.success==='yes' || sucessArray.expired === 'yes'"><span class="only_mobile">Date de certification : </span>{{sucessArray.date_validation | date: 'dd/MM/yyyy'}}</ng-container>

                                    </span>
                        </div>
                        <div class="col-md-2 col-lg-2">
                                <span class="col_date">
                                <ng-container *ngIf="sucessArray.type_validite === 2">
                                  <ng-container *ngIf="sucessArray.success==='yes' || sucessArray.expired === 'yes'">
                                    <ng-container *ngIf="sucessArray.date_dernier_passage==='1970-01-01 00:00:00'"><span class="col_valid not_attempted"><i class="bx bx-info-circle"></i> NC</span></ng-container>
                                    <ng-container *ngIf="sucessArray.date_dernier_passage!=='1970-01-01 00:00:00'"><span class="only_mobile">Date de fin de validité : </span>{{sucessArray.dateEndValidite | date: 'dd/MM/yyyy'}}</ng-container>
                                  </ng-container>
                                </ng-container>
                                  <ng-container *ngIf="sucessArray.type_validite === 1">
                                    <span class="only_mobile">Date de fin de validité : </span>{{sucessArray.date }}
                                  </ng-container>
                                  </span>
                        </div>
                      </ng-container>
                    </ng-container>

                  </ng-container>

                </ng-container>
              </div>
            </div>
          </div>

        </div>
        <!-- end row -->
      </div>
      <!-- container-fluid -->
    </div>
  </div>
</div>
<!-- End Page-content -->
<app-footer></app-footer>
