import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../../../services/login.service';
import {VerificationService} from '../../../../services/verification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfigService} from '../../../../services/config.service';
import {element} from "protractor";
import { CertificationService } from 'src/app/services/certification.service';

@Component({
  selector: 'app-certificats',
  templateUrl: './certificats.component.html',
  styleUrls: ['./certificats.component.css'],
})
export class CertificatsComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public admin_login = localStorage.getItem('admin_login');
  public certifDetails = [];
  // tslint:disable-next-line:variable-name
  public result_final = [];
  // tslint:disable-next-line:variable-name radix
  public site_id = parseInt(localStorage.getItem('site_id'));
  // tslint:disable-next-line:variable-name
  status_datas: any;
  // tslint:disable-next-line:variable-name
  new_data_to_loops: any;
  // tslint:disable-next-line:variable-name
  fake_pass: string;
  public reponseAutorisedRefusedAll = [];
  public verifications: any;
  public certificats: any;
  public certificats_site: any;
  // tslint:disable-next-line:variable-name
  public comapraison_result: any;
  // tslint:disable-next-line:variable-name
  public count_path_validating: any;
  public sucessArrays: any;
  public certif: string;
  public resultByCertificats: {};
  public certificationResults: any;
  // tslint:disable-next-line:variable-name
  public photo_url ;
  public users: any;
  public c: any;
  public user_tab = [];
  // tslint:disable-next-line:variable-name
  public single_user = {
    name : null,
    photo : null,
  };
  // tslint:disable-next-line:variable-name
  public user_info_certifications = {
    name : null,
    matricule : null,
    firstname : null,
    service : null,
    active : null,
    end_date : null,
    start_date : null,
    last_exit_date : null,
  };

  public resultFinal = [];

  private sub: any;
  // tslint:disable-next-line:variable-name
  the_finding: number;
  // tslint:disable-next-line:variable-name
  verification_id: string;
  // tslint:disable-next-line:variable-name
  verificateur_id: string;
  // tslint:disable-next-line:variable-name
  message_status_date: string;

  // tslint:disable-next-line:variable-name
  intervenant_find = {
    name : null,
    firstname : null,
    photo : null,
    society : null,
    id : null,
  };
  certifications: any;
  // tslint:disable-next-line:variable-name
  certificats_list_by_verification: any;
  // tslint:disable-next-line:variable-name
  single_verification: any;
  today: Date;
  displaying: any;
  status: any;
  // tslint:disable-next-line:variable-name
  cpt_valid = 0;
  // tslint:disable-next-line:variable-name
  cpt_not_valid = 0;
  // tslint:disable-next-line:variable-name
  cpt_certif = 0;

  // tslint:disable-next-line:variable-name
  cpt_passed = 0;
  // tslint:disable-next-line:variable-name
  cpt_failed = 0;
  cpt_not_validated = 0;

  // tslint:disable-next-line:variable-name
  show_result: boolean;
  // tslint:disable-next-line:variable-name
  currentDate = new Date();
  public fakeYes = 0;
  public fakeNo = 0;
  // tslint:disable-next-line:variable-name
  public reponseA_R_All: any;
  public isAccepted = false;
  public labelText: string;
  public nLabel: number;
  public cptCount = 0;
  public cptssCertif = 0;
  public cptCountFAke = 0;
  public rCptFake: number;
  public fakeCodeCert = 'FAKER';
  public FirstCK2;
  public certificationsLenght: any;
  // tslint:disable-next-line:variable-name
  public sub_certifications: any;
  // tslint:disable-next-line:variable-name
  public admin_verifications: any;
  // tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  // tslint:disable-next-line:variable-name
  public profile_id = this.user_conntected.profile_id;
  public takeInHand = localStorage.getItem('takeInHand');
  public labelSousCertifcation: boolean;
  // tslint:disable-next-line:variable-name
  public query_status: boolean;
  // tslint:disable-next-line:variable-name
  public query_message: any;
  public connectLikeVerifcateur = localStorage.getItem('connect_like_verificateur');
  // tslint:disable-next-line:variable-name
  public cpt_cert_and_sub_requiring: any;
  // tslint:disable-next-line:variable-name
  public cpt_comparaison_result: any;
  // tslint:disable-next-line:variable-name
  public check_requiring: any;
  // tslint:disable-next-line:variable-name
  public label_no_register: string;
  // tslint:disable-next-line:variable-name
  public certifications_list: any;
  // tslint:disable-next-line:variable-name
  public array_is_register: any;
  public certificationsSize: any;
  public CertificateCK1_number= 0;
  public CertificateCK2_number= 0;
  public certificatsParentId = [];
  // tslint:disable-next-line:variable-name
  public user_url_id: any;
  public user_id: any;
  constructor(
    public translate: TranslateService,
    public pageTitle: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private configService: ConfigService,
    private verificationService: VerificationService,
    private spinner: NgxSpinnerService,
    private config: ConfigService,
    private certificationService: CertificationService) {}

  ngOnInit() {
    this.configService.checkUSerAccessByProfile(this.profile_id);
    if(this.user_conntected.id)
    {
      this.user_id = this.user_conntected.id;
    }
    else{
      this.user_id = this.user_conntected.user_id;
    }

    this.get_certification_by_user_login(this.user_conntected.login)

    this.sub = this.activatedRoute.params.subscribe(params => {
      this.user_url_id = params.id;
    });

    // si profile apprenant (3) verification si l'url corresponds a celle de l'user qui est connecté
    if (this.user_url_id == this.user_id ||this.user_url_id){
      this.user_certifications(this.user_url_id);
    }
      else if(this.profile_id===11 || this.takeInHand =='yes'){
        this.user_certifications(this.user_url_id);
    }
    else if(this.profile_id !==11 || this.profile_id !==3) {
      this.userService.getListIntervenant(this.profile_id, this.site_id, this.user_id)
        .subscribe(res => {
          // @ts-ignore
          this.users = res.data;
          const user_tab = [];
          for (let us of this.users) {
            user_tab.push(us.user.id)
          }
          for (let id of user_tab){
            if (id == this.user_url_id){
              this.user_certifications(this.user_url_id);
            }
          }
        }, (err) => {
          console.log(err);
        });
    }
    else{
      this.router.navigate(['dashboard/administrator']);
    }
  }

  // tslint:disable-next-line:variable-name
  user_certifications(id_user) {
    this.spinner.show();
    this.userService.get_all_intervenant_certifications_status(id_user)
      .subscribe(res => {
        this.spinner.hide();
        if (this.user_url_id) {
          this.user_info_certifications.firstname = res.data.results.user.firstname;
          this.user_info_certifications.name = res.data.results.user.name;
          this.user_info_certifications.matricule = res.data.results.user.matricule;
          this.user_info_certifications.service = res.data.results.user.service;
          this.user_info_certifications.active = res.data.results.user.active;
          this.user_info_certifications.end_date = res.data.results.user.end_date;
          this.user_info_certifications.start_date = res.data.results.user.start_date;
          this.user_info_certifications.last_exit_date = res.data.results.user.last_exit_date;
        } else {
          this.user_info_certifications.firstname = this.user_conntected.firstname;
          this.user_info_certifications.name = this.user_conntected.name;
          this.user_info_certifications.matricule = this.user_conntected.matricule;
          this.user_info_certifications.service = this.user_conntected.service;
          this.user_info_certifications.active = res.data.results.user.active;
          this.user_info_certifications.end_date = res.data.results.user.end_date;
          this.user_info_certifications.start_date = res.data.results.user.start_date;
          this.user_info_certifications.last_exit_date = res.data.results.user.last_exit_date;
        }
        this.query_status = res.data.results.status;
        this.query_message = res.data.results.error;
        this.intervenant_find.id = res.data.results.user.id;
        this.intervenant_find.name = res.data.results.user.name;
        this.intervenant_find.firstname = res.data.results.user.firstname;
        this.intervenant_find.society = res.data.results.user.society;
        this.intervenant_find.photo = this.photo_url + '' + res.data.results.user.photo;

        // Display Certificats Requiring certificat
        this.certifications_list = res.data.results.certifications;
        this.certificats = res.data.results.certifications_and_subcertificats_requiring.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        this.certificationsLenght = res.data.results.certifications.length - 1;
        this.certificationsSize = res.data.results.certifications.length;

        // Cpt Requiring Cert & subCert
        this.cpt_cert_and_sub_requiring = res.data.results.certifications_and_subcertificats_requiring.length;

        // Cpt Comparaison
        this.cpt_comparaison_result = res.data.results.comapraison_result.length;

        this.comapraison_result = res.data.results.comapraison_result;
        this.sub_certifications = res.data.results.sub_certifications.length;
        this.cpt_passed = res.data.results.cpt_passed;
        this.cpt_failed = res.data.results.cpt_failed;
        this.cpt_not_validated = res.data.results.cpt_not_validated;
        this.count_path_validating = res.data.results.count_path_validating;
        this.sucessArrays = res.data.results.sucessArrays.sort((a, b) => {
          return b.parcour_id - a.parcour_id;
        });

        this.check_requiring = res.data.results.check_requiring;
        this.array_is_register = res.data.results.array_is_register;

        //test
        this.sucessArrays.forEach((certificat, cindex) => {
          if (certificat.code_cert.substring(0, 3) === 'CK2') {
            this.certificatsParentId.push(certificat.parent_certificat_id);
          }
        });

        this.certificatsParentId.forEach((parentId, pindex) => {
          this.certifications_list.forEach((certifications, csindex) => {
            if(parentId === certifications.id ){
              let info = this.sucessArrays.filter((sucessArray) => sucessArray.code_cert.toLocaleLowerCase().includes(certifications.code_cert.toLocaleLowerCase()));
              if(info == false)
                this.certificats.push(certifications);
            }
          })
        })

        const fakeCodeCert = 'FAKER';
        const fakeIndex = '';
        let cPassed = 0;
        let cFailed = 0;
        let cNotValidated = 0;
        // tslint:disable-next-line:variable-name
        let is_sub_cert;
        this.sucessArrays.forEach((sucessArray, rindex) => {
          this.certificats.forEach((certification, index) => {
            if (certification.code_cert === sucessArray.code_cert) {
              if (fakeIndex !== index) {
                cPassed = 0;
                cFailed = 0;
                cNotValidated = 0;
              }

              if (sucessArray.code_cert.substring(0, 3) === 'CK2') {
                if (this.fakeCodeCert === 'FAKER') {
                  this.labelText = 'SOUS-CERTIFICATS';
                  this.FirstCK2 = Object.values(sucessArray)[0];

                } else  {
                  this.labelText = '';
                  this.cptCountFAke++;
                }
                this.fakeCodeCert = sucessArray.code_cert;
              }
              if (sucessArray.code_cert.substring(0, 3) === 'CK2') {
                is_sub_cert = 'yes';
                this.nLabel = rindex;
              } else {
                is_sub_cert = 'no';
              }
              if (certification.code_cert === sucessArray.code_cert) {
                this.certif = 'certificat' + index;
                if (sucessArray.success === 'yes') {
                  cPassed++;
                }
                if (sucessArray.failed === 'yes') {
                  cFailed++;
                }
                if (sucessArray.incomplete === 'yes') {
                  cFailed++;
                }
                if (sucessArray.notvalidated === 'yes') {
                  cNotValidated++;
                }
                const data = {
                  certificat : this.certif,
                  code_cert : sucessArray.code_cert,
                  certificat_id : sucessArray.certificat_id,
                  parent_certificat_id : sucessArray.parent_certificat_id,
                  result: {
                    failed: cFailed,
                    passed: cPassed,
                    not_validated: cNotValidated,
                    is_sub_cert,
                  }
                };
                this.resultFinal.push(data);
              }

            }
          });
          this.cpt_certif++;
        });
        this.resultByCertificats = this.groupByCertificat(this.resultFinal);
        this.certificationResults = Object.keys(this.resultByCertificats).map((key) => this.resultByCertificats[key]);
        const resultats = this.resultByCertificats;
        // tslint:disable-next-line:only-arrow-functions
        let nResponseAutorised = 0;
        let nResponseRefused = 0;
        const fakeResponse = '';
        let reponse = '';
        // tslint:disable-next-line:only-arrow-functions forin
        for (const key in resultats) {
          if (fakeResponse !== key) {
            reponse = '';
          }
          if (resultats[key].passed > resultats[key].failed  || resultats[key].passed < resultats[key].failed) {
            // tslint:disable-next-line:variable-name
            nResponseAutorised++;
            reponse = 'valide';
          }
          if (resultats[key].passed >= 1) {
            // tslint:disable-next-line:variable-name
            nResponseAutorised++;
            reponse = 'valide';
          } else {
            nResponseRefused++;
            reponse = 'refuse';
          }
          const responseAccess = {
            reponse,
            is_sub_cert: resultats[key].is_sub_cert,
            parent_certificat_id: resultats[key].parent_certificat_id,
            certificat_id: resultats[key].certificat_id,
          };
          this.reponseAutorisedRefusedAll.push(responseAccess);
        }

        let cpAnswer = 0;
        let cpAnswerSub = 0;
        const sizeAnswer = this.reponseAutorisedRefusedAll.length;
        let sizeAnswerSub = 0;
        const certParent = [];
        let cpAnswerNotSub = 0;
        this.reponseAutorisedRefusedAll.forEach((answer, rindex) => {
          // @ts-ignore
          if (answer.reponse === 'valide') {
            cpAnswer++;
          }
          if (answer.is_sub_cert === 'yes') {
            if (answer.reponse === 'valide') {
              cpAnswerSub++;
              certParent.push(answer.parent_certificat_id);
            }
            sizeAnswerSub++;
          }
          if (answer.is_sub_cert === 'no') {
            if (answer.reponse === 'valide') {
              cpAnswerNotSub++;
            }
          }
        });
        if (this.check_requiring === 'no' && this.sub_certifications === 0) {
          // tslint:disable-next-line:max-line-length
          if (this.cpt_cert_and_sub_requiring < this.cpt_comparaison_result || this.cpt_cert_and_sub_requiring > this.cpt_comparaison_result) {
            this.isAccepted = false;
          } else {
            this.isAccepted = true;
          }
        }
        if (sizeAnswerSub >= 1) {

          if (cpAnswerSub < sizeAnswerSub) {
            this.labelSousCertifcation = true;
          } else {
            this.labelSousCertifcation = false;
          }
          const rsponseConsideringSubCert = [];
          // Nouvelle size
          const newSize = sizeAnswer - sizeAnswerSub;

          let n = 0;
          if (cpAnswerSub === sizeAnswerSub) {
            const responseAccess = {
              reponse: 'valide',
              parent_certificat_id: certParent[0],
              certificat: 0,
            };
            rsponseConsideringSubCert.push(responseAccess);
          } else if (cpAnswerSub === 0 || cpAnswerSub > 0) {
            if (cpAnswer === sizeAnswer) {
              this.isAccepted = true;
            } else {
              this.isAccepted = false;
            }
          } else {
            const responseAccess = {
              reponse: 'refuse',
              parent_certificat_id: certParent[0],
              certificat: 0,
            };
            rsponseConsideringSubCert.push(responseAccess);
          }

          for (const rep of this.reponseAutorisedRefusedAll) {
            const data = {
              reponse : rep.reponse,
              parent_certificat_id: rep.parent_certificat_id,
              certificat_id : rep.certificat_id,
              testing : 'ok',
            };
            rsponseConsideringSubCert.push(data);
            n++;
            if (newSize === n) { break; }
          }
          let tempCertId = '';
          let certID = '';
          const FinalResponse = [];
          for (const rsp of rsponseConsideringSubCert) {
            if (rsp.parent_certificat_id.length === 0) {
              certID = rsp.certificat_id;
            }
            if (tempCertId === certID) {
              const data = {
                reponse : 'valide',
              };
              FinalResponse.push(data);
            } else {
              const data = {
                reponse : rsp.reponse,
              };
              FinalResponse.push(data);
            }
            tempCertId = rsp.parent_certificat_id;
          }

          let cpSousAnswer = 0;
          let cpSousAnswerFalse = 0;
          FinalResponse.forEach((answer, rindex) => {
            // @ts-ignore
            if (answer.reponse === 'valide') {
              cpSousAnswer++;
            } else {
              cpSousAnswerFalse++;
            }
          });
          if (this.sub_certifications > 0) {
            if (cpAnswer === 0) {
              this.isAccepted = false;
            }
            if (cpAnswerSub === this.sub_certifications) {
              this.labelSousCertifcation = false;

              if (this.certificationsSize === cpAnswerNotSub) {
                this.isAccepted = true;
              } else {
                this.isAccepted = false;
              }

              // tslint:disable-next-line:align
            } else {
              if (this.certificationsSize === cpAnswerNotSub) {
                this.isAccepted = true;
              } else {
                this.isAccepted = false;
              }
              this.labelSousCertifcation = true;
            }
          } else {
            this.isAccepted = false;
          }
        }  else {
          if (cpAnswer === sizeAnswer) {
            this.isAccepted = true;
          } else {

            this.isAccepted = false;
          }

          if (this.certificationsSize === cpAnswerNotSub) {
            this.isAccepted = true;
          } else {
            this.isAccepted = false;
          }
        }
        // tslint:disable-next-line:variable-name
        this.fake_pass = '';
        // tslint:disable-next-line:variable-name
        this.spinner.hide();
        this.show_result = true;
      }, (err) => {
        console.log(err);
        this.router.navigate(['dashboard/administrator']);
        return false;
      });
  }
  groupByCertificat(dataResult) {
    const distinctCount = {};
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < dataResult.length; i++) {
      const cert = dataResult[i];
      const certName = cert.certificat;
      // tslint:disable-next-line:variable-name
      const result_failed  = cert.result.failed;
      // tslint:disable-next-line:variable-name
      const result_passed = cert.result.passed;
      // tslint:disable-next-line:variable-name
      const result_not_validated = cert.result.not_validated;
      // tslint:disable-next-line:variable-name
      const result_code_cert = cert.code_cert;
      // tslint:disable-next-line:variable-name
      const result_is_sub_cert = cert.result.is_sub_cert;
      // tslint:disable-next-line:variable-name
      const result_parent_certificat_id = cert.parent_certificat_id;
      // tslint:disable-next-line:variable-name
      const result_certificat_id = cert.certificat_id;

      if (!distinctCount[certName]) {
        // tslint:disable-next-line:max-line-length
        distinctCount[certName] = { failed: result_failed, passed: result_passed, not_validated: result_not_validated ,code_cert: result_code_cert, is_sub_cert: result_is_sub_cert, certificat_id: result_certificat_id, parent_certificat_id: result_parent_certificat_id};
      } else {
        distinctCount[certName].failed += result_failed;
        distinctCount[certName].passed += result_passed;
        distinctCount[certName].not_validated += result_not_validated;
      }
    }
    return distinctCount;
  }

  // tslint:disable-next-line:variable-name
  get_certification_by_user_login(login) {
    const body = {
      login : login,
    };
    this.spinner.show();
    this.certificationService.get_certification_by_user_login(body)
      .subscribe(res => {
        // @ts-ignore
        this.certificats_site = res.data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        this.spinner.hide();
      }, (err) => {
        console.log(err);

      });
  }

}
